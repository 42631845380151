.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cabecalho {
  background-color: #1c7cd5 !important;
}

a.active {
  background-color: #fff !important;
  color: #333 !important;
}

.App-header {
  background-color: #1c7cd5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rolagem {
  max-height: 100%;
  overflow-y: auto;
}

.print {
  page-break-after: always;
}

.page-full {
  max-height: 100%;
  height: 100%;
}

.page-100 {
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
}

.qrcode {
  width: 150px;
  height: 150px;
  border: 1px solid #000;
  margin-top: 4px;
  margin-bottom: 4px;
}

.perfis-rolagem {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 1s0px;
}

.linhaTabela {
  vertical-align: middle;
}

.border-dashed {
  border-style: dashed !important;
  border-color: #ced4da !important;
}

.border-dotted {
  border-style: dotted !important;
  border-color: #ced4da !important;
}

.text-adapt {
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
